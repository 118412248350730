import React,{useState,useEffect,useRef}  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Data from "../config/DJs"
import Edit from "../components/form/Edit";
import {BaseSelect,BaseFormField,FormSubmit} from "../styles/Global"
import { GetList } from "../utils/ApiFunctions";
import axios from "axios";
import mainjson from '../config/main';
import writeXlsxFile from 'write-excel-file'

const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const ListContainer = styled.div`
  padding-bottom:20px;
`;

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 400;
`;


const InputContainer = styled.div`
 
`;

const Select = styled(BaseSelect)`

`

const Top = styled.div`
padding:25px;

`

const Container = styled.div`
padding:25px;
background-color:white;
`
export default function Page() {
    const [options, setOptions] = useState([]);
    const [text, setCurrentText] = useState(null);
    const input = useRef();
    const inputRef = useRef();
    const [value, setValue] = useState(null);
    const [valueTo, setValueTo] = useState(null);
    const [copied, setCopied] = useState(false);
    const [copying, setCopying] = useState(false);
    const [ready, setReady] = useState(false);
    const [importasvalue, setimportasValue] = useState(0);
    const filenameRef = useRef();
    const [active, setActive] = useState(true);

    function changed(e){
        setValue(e.target.value);
        checkActive();
    }

    function changedTo(e){
        setValueTo(e.target.value);
        checkActive();
    }

    function importaschanged(e){
        setimportasValue(e.target.value);
    }

    async function getFeed(){
        let data = await GetList("campaigns",0,9999999,"campaign_startdate asc","",{},-1);
        let clientsdata = await GetList("campaignclients",0,9999999,"campaign_client_id asc","",{},-1);

        let clients = {};
        for (let i = 0;i<clientsdata.records.data.length;i++) {
            let client = clientsdata.records.data[i];
            
            clients[client.campaign_client_id] = client.campaign_client_name;
        }
        console.log("clientsdata",clients);
      
        let optionarray = [];
        for (let i =0;i<data.records.data.length;i++) {
            let campaign = data.records.data[i];
            let clientname = "unknown";
            if (clients[campaign.campaign_client_id]) {
                clientname = clients[campaign.campaign_client_id];
            }

            optionarray.push({key:campaign.campaign_id,value:clientname + "/"+campaign.campaign_name});
        }
        console.log("options",optionarray);
        setOptions(optionarray);
    }

    function checkActive(){
        console.log("checkactive ",value,valueTo);
        if (value != null && valueTo != null) {
            console.log("checkactive ready!");
            setReady(true);
        }
    }

    function makeData(data) {
        let d = data;
        if (typeof data == 'string') {
            d = JSON.parse(data);
        }
        let returndata = [];

        for (let i = 0;i<d.length;i++) {
            let r = d[i];
            let newrow = [];
            for (let i2 = 0;i2<r.length;i2++) {
               // console.log("dit is een row ",i,i2,r[i2]);

                let col = r[i2];

                if (i == 0) {
                    newrow.push({
                        value: col,
                        fontWeight: 'bold'
                    });
                    
                }
                else {

                    newrow.push({
                        value: col
                    });
                }
            }

            returndata.push(newrow);


            //console.log('row = ',r);

        }



        return returndata;
    }

    async function makeExcel(data){

        let outputdata = makeData(data);

       // console.log(outputdata);

        await writeXlsxFile(outputdata, {
            fileName: 'file.xlsx'  
        })
    }

    async function submit(){
        if (value == valueTo) {
            alert("You cannot copy to the same campaign");
            return;
        }

        setCopied(false)
        let resultdata = "";
        //set(true);
        let body = {
            "type":"export",
            "CMSUser-Token":localStorage.getItem('token'),
            "CMSUser-Agent":navigator.userAgent,
            "from":value,
            "to":valueTo,
            "state":importasvalue
        }
    
        await axios({
            method: "post",
            url: mainjson().copy_campaign_points_path,
            data: body,
          })
        .then(async (response) => {
           
              alert("copied");

        })
        .catch((error) => {
            console.log("error "+error);
            setCopied(true);
            setCopying(false);
        });

       
    
        return resultdata;
    }

    useEffect(() => {
        getFeed();
    }, []);   
    
    useEffect(() => {
        
        if (options[0] && value == null && valueTo == null) {
            if (options[0]) {
                setValue(options[0].key.toString());
                setValueTo(options[0].key.toString());
            }
        }
        checkActive();
    }, [options,value,valueTo]);   

  return (
    <Layout>
        <Header>
            <Title>
                    Copy Campaign Points
            </Title>

        </Header>
        <Top>
        <Container>
        <ListContainer>
                <ListTitle>Copy Campaign Points from</ListTitle>
                <Select onChange={changed} value={value}>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
            </ListContainer>
            <ListContainer>
                <ListTitle>Copy Campaign Points to</ListTitle>
                <Select onChange={changedTo} value={valueTo}>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
            </ListContainer>
            <ListContainer>
                <ListTitle>Action</ListTitle>
                <Select onChange={importaschanged} value={importasvalue}>
                <option value='0'>Copy points to campaign</option>
                </Select>
            </ListContainer>
            <ListContainer>
                {copied && <p>Copied</p>

                }
                
                {!copying && ready && 
                <FormSubmit onClick={submit}>Copy</FormSubmit>
                }
                
                {copying && " Copying, please wait"

                }
                </ListContainer>
            
        
            </Container>
            </Top>
    </Layout>
  );
}